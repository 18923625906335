<template>
  <Modal
    ref="browserWarning"
    title="Oh je, Ihr Browser ist ja uralt :-("
    disable-link
    cta-text="Verstanden"
    :cta="handleCta"
    centered-cta
    :cancel="false"
    :background-close="false"
    :close-button="false"
  >
    <p>
      Der Browser, den Sie zum Surfen benutzen, ist veraltet. Dies kann zu
      Problemen bei der Darstellung und Funktion unserer Seite führen. Wir
      empfehlen Ihnen zu einer aktuelleren Alternative zu wechseln. Der
      Nachfolger des <em>Internet Explorers</em> ist <em>Microsoft Edge</em>.
      Diesen und folgende Browser unterstützen wir:
    </p>

    <Checklist>
      <li>
        <a
          class="browser-link"
          href="https://www.microsoft.com/de-de/edge"
          target="_blank"
          rel="noopener noreferrer"
          >Microsoft Edge</a
        >
      </li>
      <li>
        <a
          class="browser-link"
          href="https://www.google.de/intl/de/chrome/"
          target="_blank"
          rel="noopener noreferrer"
          >Google Chrome</a
        >
      </li>
      <li>
        <a
          class="browser-link"
          href="https://www.mozilla.org/de/firefox/new/"
          target="_blank"
          rel="noopener noreferrer"
          >Firefox</a
        >
      </li>
    </Checklist>
  </Modal>
</template>

<script>
import { isLegacyBrowser } from 'src/lib/helpers'
import Checklist from 'src/components/common/checklist/checklist.vue'
import Modal from 'src/components/common/modal/modal.vue'

export const WARNING_VIEWED = 'browser_warning_viewed'

export default {
  name: 'OutdatedBrowserWarning',
  components: {
    Modal,
    Checklist
  },
  inject: ['$http'],
  computed: {
    isLegacyBrowser
  },
  mounted() {
    if (this.isLegacyBrowser === true) {
      this.open()
    }
  },
  methods: {
    open() {
      this.$refs.browserWarning.open('skiplink')
    },
    handleCta() {
      this.logBrowserWarningViewed()

      return true
    },
    async logBrowserWarningViewed() {
      try {
        await this.$http.post('/rest-api/v1/events', { event: WARNING_VIEWED })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.browser-link {
  color: var(--root-color);
  text-decoration: underline;
}
</style>
